<template>
  <div class="flex direction center index_page bgSea">
    <div class="topBar">
      <div class="userInfo">
        <div class="userImg" @click="$router.push('/profile')">
          <img class="avatar" :src="userAvatar" alt="" />
        </div>
        <div class="inventory">
          <div class="inventoryItem bottleNum">
            <img :src="imgs.bottle" alt="" />
            {{ remainBottleCount }}
          </div>
          <div class="inventoryItem coinNum">
            <img :src="imgs.coin" alt="" />
            {{ remainGold }}
          </div>
          <div class="inventoryItem add" @click="goShop()">
            <img :src="imgs.add" alt="" />
          </div>
        </div>
      </div>
      <div class="service">
        <div
          class="ringItem turntable"
          v-if="featureConfig.luckyWheel"
          @click="goSpin()"
        >
          <img :src="imgs.spin" alt="" />
        </div>
        <div class="ringItem-empty" v-else></div>
        <div class="rightItem">
          <div v-if="featureConfig.systemInfo" class="ringItem config">
            <img :src="imgs.notify" alt="" />
          </div>
          <div class="ringItem message" v-if="replyCount" @click="goReply">
            <img style="width: 1.2rem" :src="imgs.bottle" alt="" />
            <div class="tips">{{ replyCount }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg" ref="bgRef">
      <!-- <div class="cloud" :style="{ 'background-image': `url(${imgs.cloud})` }">
            </div> -->
      <div
        class="island"
        :style="{ 'background-image': `url(${imgs.island})` }"
      >
        <img class="tree1" :src="imgs.tree1" alt="" />
        <img class="tree2" :src="imgs.tree2" alt="" />
        <img class="tree3" :src="imgs.tree3" alt="" />
        <img class="people" :src="imgs.people" alt="" />
      </div>
    </div>
    <div class="sendBottle">
      <!-- <div class="count" v-if="isCount">Free:3/3</div>
            <div class="count" v-else><img src="/public/imgs/logo.jpeg" alt="">10</div> -->
      <div class="fishBtn" @click="fishing">
        <div style="height: 1.4rem">Fishing</div>
        <div class="count" v-if="remainFree">
          Free:{{ remainFree }}/ {{ fishTotal }}
        </div>
        <div class="count" v-else>
          <img src="/src/assets/img/主页/coin.png" alt="" />10
        </div>
      </div>
      <div class="sendBtn" @click="sendBtn()">Send Bottle</div>
    </div>
    <van-dialog v-model:show="showDaily" :showConfirmButton="false">
      <Daily @close="close()"></Daily>
    </van-dialog>
    <van-dialog
      style="background-color: rgba(0, 0, 0, 0)"
      v-model:show="showOther"
      :showConfirmButton="false"
    >
      <div class="modelBox">
        <img class="light" :src="imgs.light" alt="" />
        <img v-show="!boxOpen" class="box" :src="imgs.box1" alt="" />
        <img v-show="boxOpen" class="box" :src="imgs.box1_open" alt="" />
      </div>
    </van-dialog>
    <van-dialog v-model:show="showGet" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="showGet = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.treasure" alt="" />
        <div class="sendTitle">Treasure Chest</div>
        <div class="sendContext">
          Congratulations on catching a treasure<br />
          chest
        </div>
      </div>
    </van-dialog>
    <van-dialog v-model:show="showAnother" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="showAnother = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg" :src="imgs.pearloyster" alt="" />
        <div class="sendTitle">Pearl</div>
        <div class="sendContext">
          A pearl! Empty the bottle and send a message to the sea!
        </div>
      </div>
    </van-dialog>
    <van-dialog v-model:show="isShowNavigateShop" :showConfirmButton="false">
      <div class="SendModel">
        <div class="sendOff" @click="isShowNavigateShop = false">
          <img :src="imgs.off" alt="" />
        </div>
        <img class="sendImg_gold" :src="imgs.sendBottle_full" alt="" />
        <div class="sendTitle">not enough gold</div>
        <div class="sendContext">
          You don't have enough gold. <br />
          Would you like to go to the store to purchase more?<br />
        </div>
        <div class="sendBtn" @click="handleConfirmNavagateShop">Go</div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Daily from '../../components/daily/daily.vue'
import { getAvatar, commit } from '@/config/common/dic'
import {
  userTotalInfo,
  todayBottleNumInfo,
  getUserInitInfo,
  initParams,
  poolBottleInfo,
  bottleConfig,
  isShowFeatureConfig,
  achieveReceiveUserListRef,
  achieveListRef,
  userAvatarRef,
} from '@/config/common/total'
const appName = import.meta.env.VITE_APP_NAME
export default {
  components: { Daily },
  data() {
    return {
      boxOpen: false,
      showGet: false,
      showOther: false,
      showAnother: false,
      imgs: this.$store.state.sourceMsg,
      intervalDown: null,
      showDaily: false,
      allCount: 3,
      nowCount: 2,
      isShowNavigateShop: false,
      userAvatar: '',
    }
  },
  computed: {
    replyCount() {
      return initParams.value?.no_read_num || 0
    },
    remainGold() {
      return initParams.value?.currency?.gold >= 0
        ? initParams.value?.currency?.gold
        : 0
    },
    remainBottleCount() {
      const values = Object.values(todayBottleNumInfo.value)
      return values.reduce((a, b) => a + b, 0) || 0
    },
    // userAvatar() {
    //   return asyncGetAvatar(
    //     userTotalInfo.value?.tgid ||
    //       this.$g.getStorage('userId') ||
    //       localStorage.getItem(`${appName}_userId`)
    //   )
    // },
    remainFree() {
      return (
        (initParams.value?.fished_out_free_times >= 0
          ? initParams.value?.fished_out_free_times
          : 0) || 0
      )
    },
    fishTotal() {
      return bottleConfig.value?.daily_random_bottle_retrieve_base_limit?.value
    },
    featureConfig() {
      return isShowFeatureConfig.value
    },
  },
  async created() {
    Telegram.WebApp.BackButton.hide()
    this.$store.dispatch('changeDisableScrollEvent', true)
    Telegram.WebApp.setHeaderColor('#ACECF9')
    this.getInitParamsFn()
    this.userAvatar =
      userAvatarRef.value ||
      getAvatar(
        userTotalInfo.value?.tgid ||
          this.$g.getStorage('userId') ||
          localStorage.getItem(`${appName}_userId`)
      )
    let timer = setTimeout(async () => {
      clearTimeout(timer)
      if (achieveListRef.value.length) {
        return
      }
      const getStatus = (item) => {
        //['Go to', 'Receive', 'Finish'] 0 1 2
        if (!item?.target?.is_completion_target) {
          return 0
        }
        if (
          item?.target?.is_completion_target &&
          !item?.target?.is_reward_collection
        ) {
          return 1
        }
        if (
          item?.target?.is_completion_target &&
          item?.target?.is_reward_collection
        ) {
          return 2
        }
      }
      this.$api.achievementTask().then((res) => {
        if (res.code === 0) {
          achieveListRef.value = res?.data?.tasks.map((item) => {
            item.award = item?.reward[0]?.number
            item.status = getStatus(item)
            item.url = item?.icon_url || imgs.bottle
            return item
          })
          console.log(achieveListRef.value, 'achieveListRef.value')
          console.log(achieveReceiveUserListRef.value, 9999)
          return
        }
      })
      this.userAvatar =
        userAvatarRef.value ||
        getAvatar(
          userTotalInfo.value?.tgid ||
            this.$g.getStorage('userId') ||
            localStorage.getItem(`${appName}_userId`)
        )
    }, 500)
    // showDaily 控制
    // this.showDaily = true
  },
  mounted() {
    this.createCloud()
    document.addEventListener('touchmove', this.preventTouchScroll, {
      passive: false,
    })
    commit('Loading_over')
  },
  beforeUnmount() {
    let bgRef = this.$refs.bgRef
    clearInterval(this.intervalDown)
    document.removeEventListener('touchmove', this.preventTouchScroll)
    this.$store.dispatch('changeDisableScrollEvent', false)
  },
  methods: {
    // 生成云
    createCloud() {
      let count = 0
      this.cloudMove()
      this.intervalDown = setInterval(() => {
        this.cloudMove()
        count++
        if (count === 200) {
          clearInterval(this.intervalDown)
        }
      }, 15000)
    },
    // cloud动画
    cloudMove() {
      const bgRef = this.$refs.bgRef
      const plusOne = document.createElement('div')
      const styles = {
        width: '14.1rem',
        height: '2.7rem',
        position: 'absolute',
        top: '-5.75rem',
        left: '-15.6rem',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${this.imgs.cloud})`,
        transition: 'transform 25s linear',
        zIndex: '0',
      }
      Object.assign(plusOne.style, styles)
      bgRef.appendChild(plusOne)
      let timer = setTimeout(() => {
        clearTimeout(timer)
        // 获取屏幕宽度
        const screenWidth = window.innerWidth
        // 获取元素的宽度
        const elementWidth = plusOne.offsetWidth
        plusOne.style.transform = `translateX(${screenWidth + elementWidth}px)`
        let timer_sub = setTimeout(() => {
          clearTimeout(timer_sub)
          plusOne.style.display = `none`
          plusOne.remove()
        }, 25000)
      }, 100) // 100毫秒延迟
    },
    preventTouchScroll(event) {
      event.preventDefault()
    },
    sendBtn() {
      this.$router.push({ path: '/sendBottle' })
    },
    goShop() {
      this.$router.push({ path: '/shopping' })
    },
    goSpin() {
      this.$router.push({ path: '/turntable' })
    },
    close() {
      this.showDaily = false
    },
    goReply() {
      if (!this.replyCount) {
        return
      }
      this.$api.getFirstMessage().then((res) => {
        if (res.code === 0) {
          if (res.data?.age) {
            poolBottleInfo.value = res.data
            console.log(poolBottleInfo.value, 'poolBottleInfo.value ->>>')
            this.$router.push({ path: '/getReplyBottle' })
          }
        }
      })
    },
    // 钓鱼
    fishing() {
      if (this.remainGold >= 10 || this.remainFree > 0) {
        this.$router.push({ path: '/fishing' })
        getUserInitInfo()
      } else {
        this.isShowNavigateShop = true
      }
      // 钓到瓶子
      // this.$router.push({path:'/getBottle'})

      // 钓到宝箱
      // this.boxOpen = false
      // this.showOther = true
      // setTimeout(() => {
      //     this.boxOpen = true
      // },300)
      // setTimeout(() => {
      //     this.showOther = false
      //     this.showGet = true
      // },1000)

      // 钓到杂物
      // this.showAnother = true
    },
    handleConfirmNavagateShop() {
      this.isShowNavigateShop = false
      this.$router.push('/shopping')
    },
    getInitParamsFn() {
      this.$api.getInitParams().then((res) => {
        if (res.code === 0) {
          initParams.value = res.data
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.bgSea {
  background: linear-gradient(to bottom, #acecf9, #d6fdfa);
  height: 31vh;
  position: relative;
}
.modelBox {
  width: 100%;
  height: 8.9rem;
  position: relative;
  // background-color: #fff;
  .light {
    position: absolute;
    top: 0;
    left: 3rem;
    width: 10.4rem;
    height: 10.4rem;
  }
  .box {
    position: absolute;
    top: 2.3rem;
    left: 5.5rem;
    width: 5.4rem;
    height: 5.4rem;
  }
}
.topBar {
  position: fixed;
  top: 0;
  height: 2rem;
  width: 95%;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}
.userInfo {
  display: flex;
}
.userImg {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  margin-right: 0.5rem;
  .avatar {
    width: 100%;
    border-radius: 100%;
    background: url('/imgs/bottleType/drift_sea_logo.jpg');
    background-size: contain;
    z-index: 1;
  }
}
.service {
  display: flex;
  justify-content: space-between;
  width: 3.9rem;
}
.ringItem {
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 100%;
  background-color: #daf6fa;
  img {
    width: 100%;
    border-radius: 100%;
  }
}
.rightItem {
  display: flex;
  flex-direction: column;
  height: 3.9rem;
  justify-content: space-between;
}
.ringItem-empty {
  width: 1.7rem;
  height: 1.7rem;
}
.inventory {
  height: 1rem;
  min-width: 6rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(218, 246, 250, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .inventoryItem {
    margin: 0 0.1rem;
    display: flex;
    align-items: center;
  }
  .bottleNum img,
  .coinNum img {
    margin-right: 0.2rem;
  }
  img {
    width: 1rem;
  }
}
.message {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.tips {
  position: absolute;
  width: 0.8rem;
  height: 0.8rem;
  background-color: #fb1a4f;
  border-radius: 100%;
  color: white;
  font-size: 0.5rem;
  line-height: 0.8rem;
  top: 0;
  right: 0;
}
.sendBottle {
  position: fixed;
  bottom: 6rem;
}
.sendBtn {
  width: 9rem;
  height: 2.5rem;
  text-align: center;
  line-height: 2.5rem;
  background-color: #fff;
  color: #39cce9;
  border: 1px solid;
  border-radius: 2rem;
  margin-top: 0.8rem;
}
.fishBtn {
  width: 9rem;
  height: 2.5rem;
  text-align: center;
  background-color: #fff;
  color: #39cce9;
  border: 1px solid;
  border-radius: 2rem;
}
.count {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  color: #8698b7;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 0.8rem;
    height: 0.8rem;
    margin-right: 0.2rem;
    border-radius: 1rem;
  }
}
.bg {
  position: absolute;
  bottom: -5rem;
  .cloud {
    position: absolute;
    width: 14.1rem;
    height: 2.7rem;
    top: -5.75rem;
    left: -11.6rem;
    background-size: contain;
    background-repeat: no-repeat;
    // animation: moveLeft 30s linear forwards;
  }
  .island {
    width: 15.7rem;
    height: 6.35rem;
    background-size: contain;
    position: relative;
    img {
      position: absolute;
    }
    .tree1 {
      top: -1rem;
      left: 4.9rem;
      width: 2.3rem;
      height: 3.05rem;
      animation: rotateLeft 4s linear infinite;
      transform-origin: bottom center;
    }
    .tree2 {
      top: -0.65rem;
      right: 3.6rem;
      height: 3.25rem;
      width: 2rem;
      animation: rotateRight 4s linear infinite;
      transform-origin: bottom center;
    }
    .tree3 {
      top: -0.3rem;
      left: 6.4rem;
      width: 1.5rem;
      height: 2rem;
      animation: rotateRight 4s linear infinite;
      transform-origin: bottom center;
    }
    .people {
      width: 2.15rem;
      height: 4.3rem;
      bottom: 3.5rem;
    }
  }
}

.SendModel {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .sendOff {
    z-index: 2005;
    position: absolute;
    right: 0;
    top: -2.2rem;
    width: 1.7rem;
    height: 1.7rem;
    background-color: #fff;
    border-radius: 100%;
    img {
      width: 100%;
    }
  }
  .sendImg {
    width: 4.9rem;
    height: 3.55rem;
    margin: 1rem 0;
  }
  .sendTitle {
    color: #010101;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.3rem 0;
  }
  .sendContext {
    color: #8698b7;
    font-size: 0.7rem;
  }
  .sendBtn {
    width: 10rem;
    height: 2.5rem;
    color: white;
    background: #39cce9;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    line-height: 2.5rem;
    font-size: 0.85rem;
  }
}
@keyframes rotateLeft {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotateRight {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes moveLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}
.sendImg_gold {
  width: 3.55rem;
  height: 4.65rem;
}
</style>
